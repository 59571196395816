

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'order_';

export default {
  path: routePre + '/order',
  name: 'order',
  header: 'order',
  redirect: {
    name: `${pre}console`,
  },
  component: LayoutMain,
  children: [
	{
	  path: 'console',
	  name: `${pre}console`,
	  meta: {
	    auth: ['order-console'],
	    title: '工作台',
	  },
	  component: () => import('@/pages/order/console/index'),
	},
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['order-index'],
	    title: '订单管理',
	  },
	  component: () => import('@/pages/order/index/index'),
	},
	
	{
	  path: 'create',
	  name: `${pre}create`,
	  meta: {
	    auth: ['order-create'],
	    title: '创建订单',
		activeMenu: routePre + '/order/index',
		hiddenMenu:true
	  },
	  component: () => import('@/pages/order/index/create'),
	},
	
	{
	  path: 'update',
	  name: `${pre}update`,
	  meta: {
	    auth: ['order-update'],
	    title: '修改订单',
		activeMenu: routePre + '/order/index',
	  },
	  component: () => import('@/pages/order/index/update'),
	},
	
	{
	  path: 'refund',
	  name: `${pre}refund`,
	  meta: {
	    auth: ['order-refund'],
	    title: '退款管理',
	  },
	  component: () => import('@/pages/order/refund/index'),
	},
	
	
	{
	  path: 'refund/create',
	  name: `${pre}refundCreate`,
	  meta: {
	    auth: ['order-refund-create'],
	    title: '申请退款',
		activeMenu: routePre + '/order/index',
		hiddenMenu:true
	  },
	  component: () => import('@/pages/order/refund/create'),
	},
	
	{
	  path: 'refund/update',
	  name: `${pre}refundUpdate`,
	  meta: {
	    auth: ['order-refund-update'],
	    title: '修改退款',
		activeMenu: routePre + '/order/refund',
	  },
	  component: () => import('@/pages/order/refund/update'),
	},
	
	{
	  path: 'bill/:type?',
	  name: `${pre}bill`,
	  meta: {
	    auth: ['order-bill'],
	    title: '收款账单',
	  },
	  component: () => import('@/pages/order/bill/index'),
	},
	
	{
	  path: 'bill/create/:type?',
	  name: `${pre}billCreate`,
	  meta: {
	    auth: ['order-bill-create'],
	    title: '录入收款',
		activeMenu: routePre + '/order/bill',
		hiddenMenu:true
	  },
	  component: () => import('@/pages/order/bill/create'),
	},
	
	{
	  path: 'bill/update/:bill_no?',
	  name: `${pre}billUpdate`,
	  meta: {
	    auth: ['order-bill-update'],
	    title: '修改收款',
		activeMenu: routePre + '/order/bill/updateing',
	  },
	  component: () => import('@/pages/order/bill/update'),
	},
	
	{
	  path: 'bill/repair/:bill_no?',
	  name: `${pre}billRepair`,
	  meta: {
	    auth: ['order-bill-repair'],
	    title: '修改收款',
		activeMenu: routePre + '/order/bill/complete',
		hiddenMenu:true
	  },
	  component: () => import('@/pages/order/bill/repair'),
	},
	
	{
	  path: 'stages',
	  name: `${pre}stages`,
	  meta: {
	    auth: ['order-stages'],
	    title: '分期管理',
	  },
	  component: () => import('@/pages/order/stages/index'),
	},
	
	{
	  path: 'stages/create',
	  name: `${pre}stagesCreate`,
	  meta: {
	    auth: ['order-stages'],
	    title: '办理分期',
		activeMenu: routePre + '/order/stages',
		hiddenMenu:true
	  },
	  component: () => import('@/pages/order/stages/create'),
	},
	
	{
	  path: 'stages/update',
	  name: `${pre}stagesUpdate`,
	  meta: {
	    auth: ['order-stages'],
	    title: '办理分期',
		activeMenu: routePre + '/order/stages',
	  },
	  component: () => import('@/pages/order/stages/update'),
	},
	
  ],
};
