<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.handleMatchMedia();
	if(this.$store.state.login.companyInfo){
		document.title = this.$store.state.login.companyInfo.name
	}
  },
  created(){
	  // 1. 首次加载页面的空白时间
	  // 2. 动态路由的加载与渲染造成的白屏
	  let dom = document.getElementById("pageInitLoading");
	  if (dom) {
		document.body.removeChild(document.getElementById("pageInitLoading"));
	  }
  },
  methods: {
    ...mapMutations('media', ['setDevice']),
    
    handleMatchMedia() {
      const matchMedia = window.matchMedia;
  
      if (matchMedia('(max-width: 600px)').matches) {
        var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
        let css = 'calc(100vw/7.5)';
        document.documentElement.style.fontSize = css;
        this.setDevice('Mobile');
      } else if (matchMedia('(max-width: 992px)').matches) {
        this.setDevice('Tablet');
      } else {
        this.setDevice('Desktop');
      }
    }
  },
}
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  font-family: PingFang SC, Arial, Microsoft YaHei, sans-serif;
}


html.dark{
	background-color: #141414;
	
}
</style>
